import {configureStore} from '@reduxjs/toolkit';
import usersReducer from './usersSlice';
import cardReducer from './cardSlice';
import labReducer from './labSlice';
import appointmentReducer from './appointmentSlice';
import DoctorReducer from './DoctorSlice';
import DepartmentReducer from './DepartmentSlice';
import donationReducer from './donationSlice';
const Store=configureStore({
    reducer:{
        users:usersReducer,
        card:cardReducer,
        lab:labReducer,
        appointment:appointmentReducer,
        donation:donationReducer,
        Doctor:DoctorReducer,
        Department:DepartmentReducer,
    }
})



export default Store;