import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Modal } from "antd";
import {
  deleteappointment,
  fetchappointment,
  getByIdSappointment,
} from "../../store/appointmentSlice";
import { deleteDepartment } from "../../store/DepartmentSlice";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
function AppointmentTable() {
  const dispatch = useDispatch();

  const { data: appointment, status2 } = useSelector(
    (state) => state?.appointment
  );
  console.log("ddddddddddddd", appointment);

  useEffect(() => {
    dispatch(getByIdSappointment(localStorage.getItem("userRegister")));
  }, []);

  $(document).ready(function () {
    setTimeout(function () {
      $("#AllData3").DataTable({
        pagingType: "full_numbers",
        pageLength: 10,
        processing: true,
        bDestroy: true,
        // dom: 'Bfrtip',
        // buttons: ['copy', 'csv', 'print']
      });
    }, 1000);
  });

  const onDeleteStudent = (id) => {
    Modal.confirm({
      title: "Are you sure you want to Delete?",
      onOk: () => {
        dispatch(deleteappointment(id));
      },
    });
  };

  const filterData = () => {
    const startDate = document.getElementById("startDate").value;
    const endDate = document.getElementById("endDate").value;

    dispatch(getByIdSappointment(localStorage.getItem("userRegister")), {
      startDate: startDate,
      endDate: endDate,
    });
  };

  return (
    <>
      <div className="m-4">
        <div className=" container-fluid bg-white  rounded-3 shadow pt-4">
          <div className="row pb-4 align-items-center">
            <div className="col-md-4">
              <h3>Appointments</h3>
            </div>
          </div>
          <div className="col-md-12 text-end pt-4 p-3">
            <Link
              type="Register"
              to="/make_appointment"
              className="btn mb-3 btn-info text-white"
            >
              Make an Appointment
            </Link>

            <div className="table-responsive">
              <table class="table table-striped text-start" id="AllData3">
                <thead>
                  <tr>
                    <th scope="col">SL</th>
                    <th scope="col">Doctor Name</th>
                    <th scope="col">Appointment Time</th>
                    {/* <th scope="col">List Appointment</th> */}
                    <th scope="col">Customer Name</th>
                    <th scope="col">Appointment Date</th>
                    <th scope="col">Status</th>

                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {appointment?.appointmentU &&
                    appointment?.appointmentU?.map((item, index) => {
                      return (
                        <>
                          <tr>
                            <td scope="row">{index + 1}</td>
                            <td>{item?.doctor?.name}</td>
                            <td>{item?.appointmentTime}</td>
                            <td>{appointment?.username}</td>
                            <td>{item?.appointmentDate}</td>
                            <td>
                              {item?.status === "pending" ? (
                                <span className="badge text-bg-warning">
                                  {item?.status}
                                </span>
                              ) : (
                                <span className="badge text-bg-success">
                                  {item?.status}
                                </span>
                              )}
                            </td>

                            <td>
                              <div className=" d-flex justify-content-center">
                                <Link to={`/edit_appointment/${item?.id}`}>
                                  <i
                                    style={{ color: "blue", fontSize: "20px" }}
                                    class="bi bi-pencil-square"
                                  ></i>
                                </Link>
                                <i
                                  onClick={() => {
                                    onDeleteStudent(item?.id);
                                  }}
                                  style={{ color: "#a0252b", fontSize: "20px" }}
                                  class="bi ps-3 bi-trash3"
                                ></i>
                              </div>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AppointmentTable;
