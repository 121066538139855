import { Link, NavLink } from "react-router-dom";
function Sidebar({ width, display }) {

    const NavLinkStyle = ({ isActive }) => {
        return {
            background: isActive ? 'rgba(255,255,255,0.4)' : '',
            borderLeft: isActive ? '0px' : 'none',
            borderRadius: isActive ? '30px' : 'none',
            margin: isActive ? '5px' : 'none',
            color: isActive ? 'white' : '',
        }
    }
    return (
        <aside className="sidebar-wrapper" data-simplebar="true" style={{width:width}}>
            <div className="sidebar-header p-5 pb-2 pt-3">
                <div className="bg-white p-2 rounded-4 ">
                    <img
                        src={require('../logo.png')}
                        className="logo-icon"
                        alt="logo icon"
                        width='160px'
                    />
                </div>
            </div>
            <ul className="metismenu">
                <li>
                    <NavLink style={NavLinkStyle}
                        to="/dashboard"
                        className="accordion-button d-flex gap-2"
                        type="button"
                       
                    >
                        <div className="parent-icon">
                        <i className="bi bi-house px-3" />
                        </div>
                        <div className="menu-title">Dashboard</div>
                    </NavLink>
                </li>

                <li>
                    <NavLink style={NavLinkStyle}
                        to="/AppointTable"
                        className="accordion-button  d-flex gap-2"
                        type="button"
                      
                    >
                        <div className="parent-icon">
                        <i className="bi bi-house px-3" />
                        </div>
                        <div className="menu-title">Make a appointment</div>
                    </NavLink>
                 
                </li>


                <li>
                    <NavLink style={NavLinkStyle}
                        to="/make_donation"
                        className="accordion-button  d-flex gap-2"
                        type="button"
                  
                    >
                        <div className="parent-icon">
                        <i className="bi bi-house px-3" />
                        </div>
                        <div className="menu-title">Make a Donation</div>
                    </NavLink>
                   
                </li>


                <li>
                    <NavLink style={NavLinkStyle}
                        to="/lab_Tabel"
                        className="accordion-button  d-flex gap-2"
                        type="button"
                       
                    >
                        <div className="parent-icon">
                        <i className="bi bi-house px-3" />
                        </div>
                        <div className="menu-title"> Lab reports
</div>
                    </NavLink>
                    
                </li>
                <li>
                    <NavLink style={NavLinkStyle}
                        to="/registration_card"
                        className="accordion-button  d-flex gap-2"
                        type="button"
                    
                    >
                        <div className="parent-icon">
                        <i className="bi bi-house px-3" />
                        </div>
                        <div className="menu-title"> Registration Card
</div>
                    </NavLink>
                  
                </li>

                <li>
                    <NavLink style={NavLinkStyle}
                        to="/history_transcation"
                        className="accordion-button  d-flex gap-2"
                        type="button"
                    
                    >
                        <div className="parent-icon">
                        <i className="bi bi-house px-3" />
                        </div>
                        <div className="menu-title"> History of transaction
</div>
                    </NavLink>
                  
                </li>
                <li>
                    <NavLink style={NavLinkStyle}
                        to="/profile_management"
                        className="accordion-button  d-flex gap-2"
                        type="button"
                      
                    >
                        <div className="parent-icon">
                        <i className="bi bi-house px-3" />
                        </div>
                        <div className="menu-title"> Profile management
</div>
                    </NavLink>
                  
                </li>
                <li>
                    <NavLink style={NavLinkStyle}
                        to="/"
                        className="accordion-button  d-flex gap-2"
                        type="button"
                       
                    >
                        <div className="parent-icon">
                        <i className="bi bi-house px-3" />
                        </div>
                        <div className="menu-title"> Logout
</div>
                    </NavLink>
                  
                </li>

                
            </ul>
        </aside>

    );

}

export default Sidebar