import axios from 'axios'
import { message } from "antd";
import api_url from './ApiUrl';
const { createSlice } = require("@reduxjs/toolkit")

const STATUS = Object.freeze({
    IDLE: "idle",
    ERROR: "error",
    LOADING: "loading",
})


const usersSlice = createSlice({
    name: "users",
    initialState: {
        data: [],
        update:'',
        status: STATUS.IDLE,
    },

    reducers: {
        setusers(state, action) {
            state.data = action.payload
        },
       setStatus(state, action) {
            state.status = action.payload
        }

    }

})

export const { setusers, setStatus,getusers } = usersSlice.actions

export default usersSlice.reducer

// thunk

export function fetchusers() {
    return async function fetchusersThunk(dispatch, getState) {
        dispatch(setStatus(STATUS.LOADING))
        try {

            await axios.get(`${api_url}/users/get`).then(
                (resp) => {
                    console.log(resp.data);
                    dispatch(setusers(resp.data.data))
                })
            dispatch(setStatus(STATUS.IDLE))
        } catch (err) {
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}





export function createusers(param) {
    return async function createusersThunk(dispatch, getState) {
        dispatch(setStatus(STATUS.LOADING))
        try {

            await axios.post(`${api_url}/users/create`, param).then(res => {
                console.log(res.data)
                if (res.data?.status === "ok") {
                    message.success("users Created")
                    const main_urls=window.location.origin
                    window.location.href=`${main_urls}/#/users`;
                }
            })
            dispatch(setStatus(STATUS.IDLE))
        } catch (err) {
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}



export function loginusers(param) {
    return async function createusersThunk(dispatch, getState) {
        dispatch(setStatus(STATUS.LOADING))
        try {

            await axios.post(`${api_url}/login/create`, param).then(res => {
                console.log(res.data)
                if (res.data?.message === "User has been blocked by admin") {
                    message.error(res.data?.message)
                    
                }
                else if (res.data?.status === "Wrong Password") {
                    message.error(res.data?.status)
                    
                }
                else if (res.data?.status === "Username not found") {
                    message.error(res.data?.status)
                    
                }
                else{
                    const main_urls=window.location.origin
                    window.location.href=`${main_urls}/#/dashboard`;
                    localStorage.setItem('userRegister', res.data?.data?.id)
                    message.success(res.data?.state)
                }
            })
            dispatch(setStatus(STATUS.IDLE))
        } catch (err) {
            message.error("Invalid username or password")
            
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}





export function updateusers(id,param) {
    return async function updateusersThunk(dispatch, getState) {
        dispatch(setStatus(STATUS.LOADING))
        try {
         
            await axios.put(`${api_url}/users/update/${id}`, param).then(res => {
                console.log(res.data)
                if (res.data?.status === "ok") {
                    message.success("users Updated")
                }
            })
            dispatch(setStatus(STATUS.IDLE))
        } catch (err) {
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}






export function deleteusers(id) {
    return async function deleteusersThunk(dispatch, getState) {
        dispatch(setStatus(STATUS.LOADING))
        try {

            await axios.delete(`${api_url}/users/delete/${id}`).then(res => {
                console.log(res.data)
                if (res.data?.status === "ok") {
                    window.location.reload(true)
                }
            })
            dispatch(setStatus(STATUS.IDLE))
        } catch (err) {
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}






export function getByIdusers(id) {
    return async function getByIdusersThunk(dispatch, getState) {
        dispatch(setStatus(STATUS.LOADING))
        try {

            await axios.get(`${api_url}/users/get/${id}`).then(
                (resp) => {
                    console.log(resp.data);
                    dispatch(setusers(resp.data.data))
                })
            dispatch(setStatus(STATUS.IDLE))
        } catch (err) {
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}