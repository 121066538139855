import axios from 'axios'
import { message } from "antd";
import api_url from './ApiUrl';
const { createSlice } = require("@reduxjs/toolkit")

const STATUS = Object.freeze({
    IDLE: "idle",
    ERROR: "error",
    LOADING: "loading",
})


const cardSlice = createSlice({
    name: "card",
    initialState: {
        data: [],
        update:'',
        status: STATUS.IDLE,
    },

    reducers: {
        setcard(state, action) {
            state.data = action.payload
        },
       setStatus(state, action) {
            state.status = action.payload
        }

    }

})

export const { setcard, setStatus,getcard } = cardSlice.actions

export default cardSlice.reducer

// thunk

export function fetchcard(id) {
    return async function fetchcardThunk(dispatch, getState) {
        dispatch(setStatus(STATUS.LOADING))
        try {

            await axios.put(`${api_url}/card/getSingle/${id}`).then(
                (resp) => {
                    console.log(resp.data);
                    dispatch(setcard(resp.data.data[0]))
                })
            dispatch(setStatus(STATUS.IDLE))
        } catch (err) {
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}





export function createcard(param) {
    return async function createcardThunk(dispatch, getState) {
        dispatch(setStatus(STATUS.LOADING))
        try {

            await axios.post(`${api_url}/card/create`, param).then(res => {
                console.log(res.data)
                if (res.data?.status === "ok") {
                    message.success("card Created Successfully!")
                  
                }
            })
            dispatch(setStatus(STATUS.IDLE))
        } catch (err) {
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}



export function logincard(param) {
    return async function createcardThunk(dispatch, getState) {
        dispatch(setStatus(STATUS.LOADING))
        try {

            await axios.post(`${api_url}/login/create`, param).then(res => {
                console.log(res.data)
                if (res.data?.status === "ok") {
                    message.success(res.data?.message)
                    const main_urls=window.location.origin
                    window.location.href=`${main_urls}/#/dashboard`;
                    localStorage.setItem('userRegister', res.data?.data?.id)
                }else{
                    message.error(res.data?.status)
                }
            })
            dispatch(setStatus(STATUS.IDLE))
        } catch (err) {
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}





export function updatecard(id,param) {
    return async function updatecardThunk(dispatch, getState) {
        dispatch(setStatus(STATUS.LOADING))
        try {
         
            await axios.put(`${api_url}/card/update/${id}`, param).then(res => {
                console.log(res.data)
                if (res.data?.status === "ok") {
                    message.success("card Updated")
                }
            })
            dispatch(setStatus(STATUS.IDLE))
        } catch (err) {
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}






export function deletecard(id) {
    return async function deletecardThunk(dispatch, getState) {
        dispatch(setStatus(STATUS.LOADING))
        try {

            await axios.delete(`${api_url}/card/delete/${id}`).then(res => {
                console.log(res.data)
                if (res.data?.status === "ok") {
                    window.location.reload(true)
                }
            })
            dispatch(setStatus(STATUS.IDLE))
        } catch (err) {
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}






export function getByIdcard(id) {
    return async function getByIdcardThunk(dispatch, getState) {
        dispatch(setStatus(STATUS.LOADING))
        try {

            await axios.get(`${api_url}/card/get/${id}`).then(
                (resp) => {
                    console.log(resp.data);
                    dispatch(setcard(resp.data.data))
                })
            dispatch(setStatus(STATUS.IDLE))
        } catch (err) {
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}