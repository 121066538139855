import React,{useEffect} from 'react'
import { CircleChart } from '../graphchart/CircleChart'
import LineCharts from '../graphchart/LineCharts'
import { useSelector,useDispatch } from 'react-redux';
import { fetchappointment} from '../store/appointmentSlice';
import { fetchdonation } from '../store/donationSlice';
import { fetchlab } from '../store/labSlice';
import { fetchDoctor } from '../store/DoctorSlice';
import { fetchusers } from '../store/usersSlice';
const Dashboard = () => {
const dispatch = useDispatch()
  const { data: appointment, status3 } = useSelector((state) => state?.appointment);
  console.log("ddddddddddddd", appointment);
  useEffect(() => {
    dispatch(fetchappointment(localStorage.getItem("userRegister")));
  }, []);


  const { data: donation, status4} = useSelector((state) => state?.donation);
  console.log("ddddddddddddd", donation);
  useEffect(() => {
    dispatch(fetchdonation(localStorage.getItem("userRegister")));
  }, []);


  
  const { data: lab, status5} = useSelector((state) => state?.lab);
  console.log("ddddddddddddd", lab);
  useEffect(() => {
    dispatch(fetchlab(localStorage.getItem("userRegister")));
  }, []);



   const { data: Doctor, status7} = useSelector((state) => state?.Doctor);
  console.log("ddddddddddddd", Doctor);
  useEffect(() => {
    dispatch(fetchDoctor(localStorage.getItem("userRegister")));
  }, []);


  const { data: users, status8} = useSelector((state) => state?.users);
  console.log("ddddddddddddd", users);
  useEffect(() => {
    dispatch(fetchusers(localStorage.getItem("userRegister")));
  }, []);



  return (
    <div className='container-fluid  mt-3'>
      <div className='row  p-4 rounded-3 shadow align-items-center' style={{backgroundColor:'#FFF9C7'}}>
      <div class="col-md-9">
						<div class="welcome-card rounded-3">
							<h4 class="text-danger">Welcome to Bach Christian Hospital!</h4>
							<p>Our server is upto dated. If you have any issue regarding this software let us by our helpline 0992-370007.</p>
							<a href='https://web.bachchristianhospital.org/' class="btn btn-warning btn-rounded">Visit Website <i class="las la-long-arrow-alt-right ms-sm-4 ms-2"></i></a>
					
							
						</div>
			</div>
      <div className='col-md-3'>
       <div>
       <img src={require("../assets/welcom-card.png")} alt=""/>
        </div>  
      </div>   
      </div>
      <div class="row pt-3">
                        <div class="col-sm-6 col-xl-3 col-lg-6">
                            <div class="widget card card-primary bg-card1">
                            <div class="card-body">
                                    <div class="media  d-flex justify-content-between">
                                        <span>
                                        <i class=" text-white fa fa-pencil-square-o" aria-hidden="true" style={{fontSize:'60px'}} ></i>
                                        </span>
                                        <div class="media-body text-left">
                                            <span class="text-white">Lab Reports</span>
                                            <h3 class="mb-0 mt-3 text-white">{lab?.lab?.length}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-xl-3 col-lg-6">
                            <div class="widget card card-danger bg-card2">
                                <div class="card-body">
                                    <div class="media  d-flex justify-content-between">
                                        <span>
                                        <i class="fa text-white fa-money" aria-hidden="true" style={{fontSize:'60px'}} ></i>
                                        </span>
                                        <div class="media-body text-left">
                                            <span class="text-white">Total Donation</span>
                                            <h3 class="mb-0 text-white mt-3">{donation?.donation?.length}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-xl-3 col-lg-6">
                            <div class="widget card card-primary bg-card3">
                            <div class="card-body">
                                    <div class="media  d-flex justify-content-between">
                                        <span>
                                        <i class="fa fa-sticky-note text-white" aria-hidden="true" style={{fontSize:'60px'}} ></i>
                                        </span>
                                        <div class="media-body text-left">
                                            <span class="text-white">Appointment</span>
                                            <h3 class="mb-0 mt-3 text-white">{appointment?.appointmentU?.length}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-xl-3 col-lg-6">
                            <div class="widget card card-primary bg-card4">
                            <div class="card-body">
                                    <div class="media  d-flex justify-content-between">
                                        <span>
                                        <i class="fa text-white fa-history" aria-hidden="true" style={{fontSize:'60px'}} ></i>
                                        </span>
                                        <div class="media-body text-left">
                                            <span class="text-white">Total History</span>
                                            <h3 class="mb-0 mt-3 text-white">{ users && users?.length}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

      <div className='row bg-white mt-3  p-4 rounded-3 shadow'>
        <div className='col-md-12'>
     <div className='' style={{overflow:'hidden'}}>

      <div className="float-end">
    
<div class="tab-content" id="pills-tabContent">
  <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">...</div>
  <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">...</div>
  <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">...</div>
</div>
      </div>
     <div>
        <LineCharts/>
     </div>
      
     </div>
        </div>
      </div>

      <div className='row mt-4'>
        <div className='col-md-6 p-0'>
        <div className='shadow rounded-3'>
        <CircleChart/>
        </div>
        </div>
        <div className='col-md-6 pe-0 rounded-3'>
            
          <div className='bg-white p-4 shadow rounded-3'>
            <div>
              <h5 className='text-bold'>Doctors</h5>
            </div>
            <div>

            </div>
            <ul className='p-0'>
              {Doctor?.map((item,index)=>{
                return (
                  <>
                     <li className='d-flex align-items-center justify-content-between'>
                <div className='d-flex'> <div>
                  <img  src={item?.image} className="" style={{borderRadius:'50%'}}  width="45px" height="45px"  alt=""/>
                </div>
                <div className='ps-3'>
                  <h6 className='m-0'>{item?.name}</h6>
                  <span className='text-primary'>{item?.designation}</span>
                </div>
                </div>
                <div>
                  <span>{item?.createdAt}</span>
                </div>
               
              </li>
              <hr className=''/>
                  </>
                )
              })}
           
              
            
            </ul>
          </div>
        </div>
      </div>
      
    </div>
  )
}

export default Dashboard
